import { Checkbox, CommandBar, DatePicker, DefaultButton, Dropdown, ICommandBarItemProps, IDropdownOption, Link, MessageBar, MessageBarType, Panel, PanelType, PrimaryButton, TextField } from '@fluentui/react';
import React from 'react';
import appstyles from '../styles/App.module.scss';
import BanksPanel from '../settings/banks/BanksPanel';
import { IBank, IPersonBankRelationship, IStakeholder } from '../../models';
import { FormMode } from '../../utils/Enums';
import useObject from '../../hooks/useObject';
import { ApplicationContext } from '../../contexts/ApplicationContext';

interface IBankRelationPanelProps
{
    selectedStakeholder: IStakeholder |undefined;
    onChanged(entity: IStakeholder): void;
    onDismiss(): void;
}

export const BankRelationPanel: React.FunctionComponent<IBankRelationPanelProps> = (props: React.PropsWithChildren<IBankRelationPanelProps>) => {
    const { Services } = React.useContext(ApplicationContext);    
    const { value: entity, updateValue: updateEntity } = useObject<IStakeholder>(props.selectedStakeholder ? props.selectedStakeholder : {} as IStakeholder);
    const [banks, setBanks] = React.useState<IBank[]>([]);      
    const [selectedBank, setSelectedBank] = React.useState<IBank>();
    const [showAddBankPanel, setShowAddBankPanel] = React.useState<boolean>(false);
    const [accountNo, setAccountNo] = React.useState<string>("");

    React.useEffect(() => {        
        fetchBanks();     
    }, []);

    const fetchBanks = (hint: number = -1) => {
        Services?.Bank.GetAll().then((result: IBank[]) => {                          
            setBanks(result);

            if(hint !== -1) {
                const bank = result.find(x => x.regNo == hint);
                setSelectedBank(bank);
            }
        });
    }

    const CreateBankRelationship = async () => {
        const payload = {
            personId: entity.personID,
            relationshipNo: 4,
            bankRegNo: selectedBank?.regNo,
            bankAccountNo: accountNo,
        } as IPersonBankRelationship;

        let result = await Services?.PersonBankRelationship.Create(payload);
        if(result === "OK")
        {
            props.onChanged(entity);
        } else
        {
            alert(result);
        }
    }

    return (<Panel isOpen={true} isLightDismiss={false} isBlocking={true} headerText='Tilføj ny bankrelation' type={PanelType.medium} onDismiss={(ev?: React.SyntheticEvent<HTMLElement> | KeyboardEvent) => { if((ev as any).keyCode !== 27) props.onDismiss(); }}  onRenderFooterContent={() => {
        return <>
            <PrimaryButton styles={{root:{marginRight:"10px"}}} onClick={() => { CreateBankRelationship() }} text="Gem" />
            <DefaultButton onClick={() => props.onDismiss() } text="Annuller" />                  
        </>
        }}>
        <Dropdown style={{marginBottom:"5px"}} label='Vælg bank' selectedKey={selectedBank?.regNo} options={banks.map((val, i, a) => { return { key: val.regNo, text: `${val.regNo} - ${val.name}` } as IDropdownOption })} onChange={(ev, option) => { setSelectedBank(banks.find(x => x.regNo == option?.key as number)) }} />
        <MessageBar messageBarType={MessageBarType.info}>Findes banken ikke på listen? <Link onClick={() => { setShowAddBankPanel(true); }}>Opret her</Link></MessageBar>
        {showAddBankPanel && <BanksPanel formMode={FormMode.New} selected={undefined} onDismiss={(update: boolean, hint: number) => { if(update) fetchBanks(hint); setShowAddBankPanel(false); }}  />}
        <TextField label="Kontonummer" onChange={(ev, val) => { if(val !== undefined) setAccountNo(val) }} />
            <DatePicker label="Gyldig fra" />
            <DatePicker label="Gyldig til" />            
    </Panel>);
}

export default BankRelationPanel;