import { IExport } from "../models/IExport";
import { CreateAuthHeader } from "../utils/Helpers";
import { IService } from "./ICommonServices";

export class ExportService
{
    private controller = "api/exports";

    public async Create(payload: IExportRequest): Promise<IExport> 
    {
        const requestOptions = {
            method: 'POST',
            headers: CreateAuthHeader(),
            body: JSON.stringify(payload)
        };
        
        const response = await fetch(this.controller, requestOptions);
        if(!response.ok)
        {
            throw new Error("Export not working");
        }

        return await response.json() as IExport;
    }
}

export interface IExportRequest
{
    type: number;
    payload: any;
}

export interface IPayoutLetterRequest
{
    period: string;
}

export interface IServiceLetterRequest
{
    year: string;
    taxGroupId: number;
}

export interface IShareControlRequest
{
    year: string;
    taxGroupId: number;
}