import jsPDF from 'jspdf';
import { IGuild } from '../models';
import { format } from "date-fns";

export const CreatePayoutReceipt = (guild: IGuild, exportResult: string) : Blob => {    
    const d = JSON.parse(exportResult) as any[];
    const supplierData : PayoutReceiptSupplierData = d[0];
    const totalData : PayoutReceiptTotalData = d[1];

    const pdf = new jsPDF();
    pdf.setProperties({
        title: `Kvittering til regnskab - ${guild.name}`
    });

    // Base styles
    pdf.setFont('Newsreader', 'normal');
    pdf.setFontSize(16);        
    pdf.setLineWidth(0.1);
        
    // Headline
    pdf.text(guild.name, AlignCenter_CalculateXOffset(pdf, guild.name), 12);
    
    // Timestamp
    const timestamp = `Udskrevet d. ${new Date().toLocaleDateString()} kl. ${new Date().toLocaleTimeString()}`;
    pdf.setFontSize(9);
    pdf.text(timestamp, AlignCenter_CalculateXOffset(pdf, timestamp), 17);

    const vendorData = {
        vendorName: "PBS",
        vendorAddress: "Laustrupbjerg 10",
        vendorAddress2: "Postboks 500",
        vendorZipCity: "2750 Ballerup"
    };

    const receiptData = {
        CVR: totalData.CVRnr,
        TransferType: "80",
        DispositionDate: format(new Date(totalData.Valørdato), "ddMMyy"),
        BankSender: `${totalData.BankRegnr} ${totalData.Banknavn}`,
        DataSupplier: supplierData.Datalev,
        DataSupplierCVR: supplierData.DatalevCVRnr,
        PaymentSenderBankAccountNo: supplierData.BankKontonr,
        TransferCount: totalData.AntalPoster + "",
        TotalAmountInDKK: totalData.SamletBeløb.toFixed(2) + "",
        ReceiverIdentification: "Møllelauget",
        PostingIdentifier: guild.name,
        ValidationNumbers: "000000000"
    };

    pdf.text(`${vendorData?.vendorName}`, 13, 44);
    pdf.text(`${vendorData?.vendorAddress}`, 13, 48)    
    pdf.text(`${vendorData?.vendorAddress2}`, 13, 52);
    pdf.text(`${vendorData?.vendorZipCity}`, 13, 56);
        
    pdf.setFont('Newsreader', 'bold');
    pdf.text('Kvittering for udbetaling', 13, 72);
    pdf.setFont('Newsreader', 'normal');
    pdf.text('Kontroloplysninger:', 13, 79);
    pdf.text('PBS overførsel:', 13, 83);
    AddTableRowTwoColumn(pdf, 91, "Møllelaugets CVR nr.", receiptData.CVR);
    AddTableRowTwoColumn(pdf, 95, "Overførselstype", receiptData.TransferType);
    AddTableRowTwoColumn(pdf, 99, "Disp. dato", receiptData.DispositionDate);
    AddTableRowTwoColumn(pdf, 103, "Betalings afsenders bank", receiptData.BankSender);
    AddTableRowTwoColumn(pdf, 107, "Dataleverandør", receiptData.DataSupplier);
    AddTableRowTwoColumn(pdf, 111, "Dataleverandør CVR nr.", receiptData.DataSupplierCVR);
    AddTableRowTwoColumn(pdf, 115, "Betalingsafsenders kontonr", receiptData.PaymentSenderBankAccountNo);
    AddTableRowTwoColumn(pdf, 119, "Antal overførsler", receiptData.TransferCount);
    AddTableRowTwoColumn(pdf, 123, "Totalbeløb i DKK", receiptData.TotalAmountInDKK);
    AddTableRowTwoColumn(pdf, 127, "Modtageridentifikation", receiptData.ReceiverIdentification);
    AddTableRowTwoColumn(pdf, 131, "Posteringsidentifikation", receiptData.PostingIdentifier);
    AddTableRowTwoColumn(pdf, 135, "Kontrol for dataleverandør", receiptData.ValidationNumbers);

    // Sender info
    pdf.text('Med venlig hilsen', 13, 150);
    pdf.text(guild.name, 13, 170);
    pdf.text(totalData.Attention, 13, 174);
    pdf.text(totalData.Adresse, 13, 177);
    pdf.text(totalData.PostnrBy, 13, 181);
    pdf.text(`Tlf. ${totalData.Telefon}`, 13, 185);

    // const pdfDataUri = pdf.output('datauristring');
    // const newTab = window.open();
    // newTab?.document.write(`<iframe width='100%' height='100%' src='${pdfDataUri}'></iframe>`);

    //return pdf.output('datauristring');
    return new Blob([pdf.output('blob')], { type: 'application/pdf' });
}

const AddTableRowTwoColumn = (pdf: jsPDF, y: number, label: string, value: string) => {
    pdf.text(label, 13, y);
    pdf.text(value, 60, y);
};

const AlignCenter_CalculateXOffset = (pdf: jsPDF, text: string) : number => {
    return (pdf.internal.pageSize.getWidth() - pdf.getTextWidth(text))/2;
};

export interface PayoutReceiptSupplierData {
    Datalev:      string;
    DatalevCVRnr: string;
    BankRegnr:    number;
    BankKontonr:  string;
    CVRnr:        string;
    Valørdato:    Date;
}

export interface PayoutReceiptTotalData {
    AntalPoster:  number;
    SamletBeløb:  number;
    Valørdato:    Date;
    BankRegnr:    number;
    BankKontonr:  string;
    DatalevCVRnr: string;
    CVRnr:        string;
    Banknavn:     string;
    ModtagerID:   string;
    Attention:    string;
    Adresse:      string;
    PostnrBy:     string;
    Telefon:      string;
}
