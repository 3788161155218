import { IUser, ILoginRequestDTO, IRequestNewPasswordDTO, IChangePasswordDTO, IResetPassword } from "../models";
import { IGuild } from "../models/IGuild";
import { CreateAuthHeader } from "../utils/Helpers";

export interface IMasterDataService
{
    Login(model: ILoginRequestDTO) : Promise<IUser | undefined>;
    SignOut(): void;
    RequestNewPassword(model: IRequestNewPasswordDTO) : Promise<boolean>;
    ResetPassword(model: IResetPassword): Promise<boolean>;
    ChangePassword(model: IChangePasswordDTO): Promise<boolean>;    
    SetGuild(guild: IGuild |undefined) : Promise<boolean>;
}

export class MasterDataService implements IMasterDataService
{    
    //private controller = "api/application";
    public async Login(model: ILoginRequestDTO) : Promise<IUser | undefined>
    {
        if(!model.email || !model.password)
        {
            return undefined;
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(model)
        };

        const response = await fetch('api/authenticate', requestOptions);
        if(response.ok)
        {
            return await response.json() as IUser;           
        }

        return undefined;
    }

    public SignOut()
    {
        sessionStorage.removeItem('uhrevind_token');
        window.location.href = "/";
    }

    public async RequestNewPassword(model: IRequestNewPasswordDTO) : Promise<boolean>
    {
        if(!model.email)
        {
            return false;
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(model)
        };

        const response = await fetch('api/requestpassword', requestOptions);
        if(!response.ok)
        {
            return false;
        }

        return true;
    }

    public async ResetPassword(model: IResetPassword): Promise<boolean>
    {
        if(!model.prFlowId || !model.password)
        {
            return false;
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(model)
        };

        const response = await fetch('api/resetpassword', requestOptions);
        if(!response.ok)
        {
            return false;
        }

        return true;
    }

    public async ChangePassword(model: IChangePasswordDTO): Promise<boolean>
    {
        if(!model.existingPassword || !model.newPassword)
        {
            return false;
        }

        const requestOptions = {
            method: 'POST',
            headers: CreateAuthHeader(),
            body: JSON.stringify(model)
        };

        const response = await fetch('api/changepassword', requestOptions);
        if(!response.ok)
        {
            return false;
        }

        return true;
    }

    public async SetGuild(guild: IGuild | undefined) : Promise<boolean>
    {
        const requestOptions = {
            method: 'POST',
            headers: CreateAuthHeader(),
            body: JSON.stringify({GuildID: guild?.id} as any)
        };

        const response = await fetch('api/application', requestOptions);
        if(response.ok)
        {
            return true;           
        } else
        {
            return false;
        }
    }

    /*public async GetUsers() : Promise<ICreateOrUpdateUserDTO[]>
    {
        const requestOptions = {
            method: 'GET',
            headers: CreateAuthHeader()
        };

        const response = await fetch('users', requestOptions);
        if(!response.ok)
        {
        }

        let users = await response.json() as ICreateOrUpdateUserDTO[];

        for(let u of users)
        {
            u.regionName = u.region?.name;
            u.supplierName = u.supplier?.name;
        }

        return users;
    }*/

    
}