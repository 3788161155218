import { IPayout } from "../models";
import { CreateAuthHeader } from "../utils/Helpers";
import { IService } from "./ICommonServices";

export class PayoutServiceProvider implements IService<IPayout>
{
    private controller = "api/payouts";

    public async GetAll(): Promise<IPayout[]> 
    {
        let result: IPayout[] = [];

        const requestOptions = {
            method: 'GET',
            headers: CreateAuthHeader()
        };
        
        const response = await fetch(this.controller, requestOptions);
        if(!response.ok)
        {
            return result;
        }

        result = await response.json() as IPayout[];

        result.forEach(p => { 
            p.start = new Date(p.start);
            p.end = new Date(p.end);
            p.dispositionDate = p.dispositionDate != null ? new Date(p.dispositionDate) : null;
            p.created = new Date(p.created);
          });

        return result;
    }

    public async Get(id: number): Promise<IPayout> 
    {
        const requestOptions = {
            method: 'GET',
            headers: CreateAuthHeader()
        };
        
        const response = await fetch(`${this.controller}/${id}`, requestOptions);
        if(!response.ok)
        {
            throw new Error("Bank not found");
        }

        return await response.json() as IPayout;
    }

    public async Create(item: IPayout) : Promise<string>
    {
        const requestOptions = {
            method: 'POST',
            headers: CreateAuthHeader(),
            body: JSON.stringify(item)
        };
    
        const response = await fetch(this.controller, requestOptions);
        return await response.text();
    }

    public async Update(item: IPayout) : Promise<string>
    {
        const requestOptions = {
            method: 'PATCH',
            headers: CreateAuthHeader(),
            body: JSON.stringify(item)
        };
    
        const response = await fetch(this.controller, requestOptions);
        return await response.text();
    }

    public async Delete(id: number) : Promise<string>
    {
        const requestOptions = {
            method: 'DELETE',
            headers: CreateAuthHeader()
        };
    
        const response = await fetch(`${this.controller}/${id}`, requestOptions);
        return await response.text();
    }   
}
