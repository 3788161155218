import React from 'react';
import { Link, MessageBar, MessageBarType, PrimaryButton, TextField } from '@fluentui/react';
import { IMasterDataService } from '../../services';
import styles from './../../styles/App.module.scss';
import { ILoginRequestDTO, IUser } from '../../models';
import { ApplicationContext } from '../../contexts/ApplicationContext';
import { useAuthenticationContext } from '../../hooks/useAuthenticationContext';
import { Navigate, NavigateFunction, useNavigate } from 'react-router-dom';
import { CustomPrimaryButton } from '../../components/Design/Buttons';
import { LoginFormType } from '../../utils/Enums';

interface ILoginProps
{
    //setToken(user: IUser): void;
}

export const Login: React.FunctionComponent<ILoginProps> = (props: React.PropsWithChildren<ILoginProps>) => {
  
  const { user, login } = useAuthenticationContext();
  const [isLoggedIn, setIsLoggedIn] = React.useState<boolean>(false);

  
  const { Services } = React.useContext(ApplicationContext);
  const [email, setEmail] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const [formType, setFormType] = React.useState<LoginFormType>(LoginFormType.login);
  const [showRequestPasswordStatus, setShowRequestPasswordStatus] = React.useState<boolean>(false);
  const [showErrorMessage, setShowErrorMessage] = React.useState<boolean>(false);
  const [showResetPasswordStatus, setShowResetPasswordStatus] = React.useState<boolean>(false);
  const [canResetPassword, setCanResetPassword] = React.useState<boolean>(false);
  const [isWorking, setIsWorking] = React.useState<boolean>(false);

  const [newPassword, setNewPassword] = React.useState<string>("");
  const [newPasswordAgain, setNewPasswordAgain] = React.useState<string>("");  

  let userService : IMasterDataService;
  let prflowId : string | null;

  React.useEffect(() => {
    //userService = ServicesFactory.UserService.Initialize(new MasterDataService());
    /*this.prflowId = new URLSearchParams(window.location.search).get("prflowId");
    let isInvitationLink = new URLSearchParams(window.location.search).get("acceptinvitation");
    let currentFormType : formType = formType.login;

    if(isInvitationLink != undefined)
    {
      currentFormType = formType.requestPassword;
    }

    if(this.prflowId != undefined)
    {
      currentFormType = formType.activateAccount;
    }*/

  }, []);

  const validateChangePassword = () =>
    {        
        let isValid : boolean = false;

        if(!newPassword || !newPasswordAgain)
        {
            isValid = false;
        } else
        {
            isValid = newPassword === newPasswordAgain;
        }

        setCanResetPassword(isValid);

        return isValid ? "" : "Din nye adgangskode er ikke angivet ens.";
    }
    let navigate: NavigateFunction = useNavigate();

  const onSubmit = async (e) =>
  {
    e.preventDefault();
    
    setIsWorking(true);
    let user : IUser | undefined = await Services?.Master.Login({ email: email, password: password } as ILoginRequestDTO);
    if(!user)
      {
        setShowErrorMessage(true); setIsWorking(false);
      } else {
        //props.setToken(user);
        login(user);
        setIsLoggedIn(true);
      }          
    }

    if (isLoggedIn) {
      return <Navigate to={"/interessenter"} />;
    }

    let form : JSX.Element = <div></div>;

  if(formType == LoginFormType.login)
  {
    form = (<div className={styles.login}>        
          <h3>Log på</h3>
          <form onSubmit={onSubmit}>
              <TextField required={true} placeholder="E-mail" value={email} onChange={(ev, newValue) => { setEmail(newValue + ""); }} />              
              <TextField required={true} placeholder="Adgangskode" type="password" canRevealPassword revealPasswordAriaLabel="Vis adgangskode" value={password} onChange={(ev, newValue) => { setPassword(newValue + ""); }} />              
              <Link className={styles.lostPasswordLink} onClick={() => { setFormType(LoginFormType.requestPassword); }}>Glemt adgangskode?</Link>
            
              <CustomPrimaryButton disabled={isWorking || email == undefined || email == "" || password == undefined || password == ""} text="Log på"/>
              { showErrorMessage && 
                <MessageBar messageBarType={MessageBarType.error}>
                  Forkert brugernavn og/eller adgangskode. Prøv igen.
                </MessageBar>                  
              }
              </form>
        </div>);
  }

  else if(formType == LoginFormType.requestPassword)
    {
      form = (<div className={styles.login}>
          <form /*onSubmit={this.onRequestNewPassword.bind(this)}*/>
            <h3>Aktiver min adgang igen</h3>
                <TextField placeholder="E-mail" value={email} onChange={(ev, newValue) => { setEmail(newValue + ""); }} />          
              <div>
                <PrimaryButton type="Submit" disabled={isWorking || email == undefined || email == ""} text={"Send aktiveringslink"} />                  
                {showRequestPasswordStatus && 
                  <div>
                    Der er nu sendt et aktiveringslink til din e-mail. Følg instruktionerne for at nulstille din adgangskode og aktivere din bruger. 
                  </div>}
              </div>
            </form>
        </div>);
    } else if(formType == LoginFormType.activateAccount)
    {
      form = (<div className={styles.login}>
        <form /*onSubmit={this.onResetPassword.bind(this)}*/>
          <h3>Nulstil din adgangskode</h3>
          <TextField 
              required={false} 
              placeholder={"Angiv din nye adgangskode" }
              type="password" 
              autoComplete="new-password"
              canRevealPassword 
              revealPasswordAriaLabel="Vis adgangskode" 
              value={newPassword} 
              onChange={(ev, newValue) => { setNewPassword(newValue + ""); }}
          />    
          <TextField 
              required={false} 
              placeholder={"Angiv din nye adgangskode igen" }
              validateOnLoad={false}
              type="password" 
              autoComplete="new-password"                
              canRevealPassword 
              revealPasswordAriaLabel="Vis adgangskode" 
              value={newPasswordAgain} 
              onGetErrorMessage={validateChangePassword.bind(this)}
              onChange={(ev, newValue) => { setNewPasswordAgain(newValue + ""); }}
          />  
            <div>
              <PrimaryButton type="Submit" className={styles.marginRight} disabled={isWorking || newPassword == undefined || newPasswordAgain == undefined} text={"Nulstil adgangskode"} />                  
              {showResetPasswordStatus && 
                <div className={styles.marginTop}>
                  Din adgangskode er nu ændret. <Link onClick={() => { window.location.href = "/"; }}>Log på igen</Link> 
                </div>}
            </div>
          </form>
      </div>);
    }

    return <>
        <div className={styles.loginContainer}>      
          {form}
        </div>
      </>
}
export default Login;