import React, { ReactNode } from 'react';
import { AuthenticationContext } from '../contexts/AuthenticationContext';
import { IRole, IUser } from '../models';
import { useSessionStorage } from '../hooks/useSessionStorage';
import { IGuild, IGuildStats } from '../models';
import { useApplicationContext } from '../hooks/useApplicationContext';
import { set } from 'date-fns';

export interface IAuthenticationProviderProps
{
}

export const AuthenticationProvider : React.FunctionComponent<IAuthenticationProviderProps> = (props: React.PropsWithChildren<IAuthenticationProviderProps>) => {    
    const { Services } = useApplicationContext();
    const [user, setUser] = React.useState<IUser|null>(null);
    const [guild, setGuild] = React.useState<IGuild|null>(null);
    const [guildStats, setGuildStats] = React.useState<IGuildStats|null>(null);
    const { getItem, setItem, removeItem } = useSessionStorage();
    const [isCheckingAuth, setIsCheckingAuth] = React.useState(true);
    
    React.useEffect(() => {
        let currentUser : IUser;

        const sessionUser = getItem("uhrevind_token");
        if (sessionUser) {
            currentUser = JSON.parse(sessionUser) as IUser;

            const guild = getItem("uhrevind_currentGuild");
            if(guild)
            {            
                setGuild(JSON.parse(guild));
            } else if (guild === null && currentUser?.guilds.length == 1) {
                setGuild(currentUser.guilds[0]);
            }        
            
            setUser(currentUser);
        }
        
        setIsCheckingAuth(false);
      }, []);

      React.useEffect(() => {
        if(guild != null && guildStats === null)
        {
            Services?.Master.SetGuild(guild);
        }
        
      }, [guild]);

    const login = (user) => {
        setUser(user);
        if(user.guilds.length == 1)
        {
            setGuild(user.guilds[0]);
        }
        setItem('uhrevind_token', JSON.stringify(user));
    };

    const setCurrentGuild = (guild) => {
        setItem('uhrevind_currentGuild', JSON.stringify(guild));
        setGuild(guild);
    }

    const logout = () => {
        setUser(null);
        removeItem('uhrevind_token');
    };

    const isUserInRole = (role: string) : boolean =>
    {
        return user?.roles.some(i => i.role.includes(role)) as boolean;
    }

    const value = {
        user,
        login,
        logout,
        isCheckingAuth,
        guild,
        guildStats,
        setGuildStats,
        setCurrentGuild,
        isUserInRole
    };

    return (
        <>
            <AuthenticationContext.Provider value={value}>
                {props.children}
            </AuthenticationContext.Provider>
        </>
    );
}

export default AuthenticationProvider;