import { IBank } from "../models";
import { IGuild } from "../models/IGuild";
import { CreateAuthHeader } from "../utils/Helpers";
import { IService } from "./ICommonServices";

export class GuildServiceProvider implements IService<IGuild>
{
    private controller = "api/guild";

    public async GetAll(): Promise<IGuild[]> 
    {
        throw new Error("Method not implemented.");
    }

    public async Get(id: string): Promise<IGuild> 
    {
        const requestOptions = {
            method: 'GET',
            headers: CreateAuthHeader()
        };
        
        const response = await fetch(`${this.controller}/${id}`, requestOptions);
        if(!response.ok)
        {
            throw new Error("Guild not found");
        }

        return await response.json() as IGuild;
    }

    public async Create(item: IGuild) : Promise<string>
    {
        throw new Error("Method not implemented.");
    }

    public async Update(item: IGuild) : Promise<string>
    {
        throw new Error("Method not implemented.");
    }

    public async Delete(id: number) : Promise<string>
    {
        throw new Error("Method not implemented.");
    }   
}
