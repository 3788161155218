import { IBank, IPayout, IPersonBankRelationship, ISales, IServiceLetter, ITaxGroup } from "../models";
import { IGuild } from "../models/IGuild";
import { IProductionOverview } from "../models/IProductionOverview";
import { BankServiceProvider } from "./BankService";
import { ExportService } from "./ExportService";
import { GuildServiceProvider } from "./GuildService";
import { PayoutServiceProvider } from "./PayoutService";
import { PersonBankRelationshipService } from "./PersonBankRelationshipService";
import { ProductionOverviewService } from "./ProductionOverviewService";
import { SalesService } from "./SalesService";
import { ServiceLetterService } from "./ServiceLetterService";
import { IStakeholderService, StakeholderServiceProvider } from "./StakeholderService";
import { TaxGroupService } from "./TaxGroupService";
import { IMasterDataService, MasterDataService } from "./UserService";

export interface ICommonServices {
    Master: IMasterDataService;
    Guild: IService<IGuild>;
    Stakeholder: IStakeholderService;
    PersonBankRelationship: IService<IPersonBankRelationship>;
    Bank: IService<IBank>;
    Payout: IService<IPayout>;
    ServiceLetter: IService<IServiceLetter>;
    Sales: IService<ISales>;
    TaxGroup: IService<ITaxGroup>;
    ProductionOverview: IService<IProductionOverview>;
    Export: ExportService;
}

export class CommonServices implements ICommonServices {
    public Master: IMasterDataService;
    public Guild: IService<IGuild>;
    public Stakeholder: IStakeholderService;
    public PersonBankRelationship: IService<IPersonBankRelationship>;
    public Bank: IService<IBank>;
    public Payout: IService<IPayout>;
    public ServiceLetter: IService<IServiceLetter>;
    public Sales: IService<ISales>;
    public TaxGroup: IService<ITaxGroup>;
    public ProductionOverview: IService<IProductionOverview>
    public Export: ExportService;
    
    constructor() {
        this.Master = new MasterDataService();
        this.Guild = new GuildServiceProvider();
        this.Stakeholder = new StakeholderServiceProvider();
        this.PersonBankRelationship = new PersonBankRelationshipService();
        this.Bank = new BankServiceProvider();
        this.Payout = new PayoutServiceProvider();
        this.ServiceLetter = new ServiceLetterService();
        this.Sales = new SalesService();
        this.TaxGroup = new TaxGroupService();
        this.ProductionOverview = new ProductionOverviewService();
        this.Export = new ExportService();
    }
}

export interface IService<T>
{
    GetAll() : Promise<T[]>;
    Get(id: number | string) : Promise<T>;
    Create(stakeholder: T) : Promise<string>;
    Update(stakeholder: T) : Promise<string>;
    Delete(id: number) : Promise<string>;
}
