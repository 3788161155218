import { createContext } from "react";
import { IGuild, IGuildStats, IUser } from '../models';

 interface AuthenticationContext {
  user: IUser | null;
  login: (user: IUser) => void;
  logout: () => void;
  isCheckingAuth: boolean;
  guild: IGuild | null;
  guildStats: IGuildStats | null;
  setCurrentGuild: (guild: IGuild | null) => void;
  setGuildStats: (stats: IGuildStats | null) => void;
  isUserInRole(role: string): boolean;
}

export const AuthenticationContext = createContext<AuthenticationContext>({
  user: null,
  login: () => {},
  logout: () => {},
  isCheckingAuth: true,
  guild: null,
  setCurrentGuild: () => {},
  guildStats: null,
  setGuildStats: () => {},
  isUserInRole: () => false,
});

