import { IContextualMenuProps, CommandButton, CompoundButton, Dialog, DialogContent, DialogType, DialogFooter, CommandBar, Persona } from '@fluentui/react';
import React from 'react';
import styles from './AppLayout.module.scss';
import { NavigateFunction, Outlet, useNavigate } from "react-router-dom";
import { useAuthenticationContext } from '../../hooks/useAuthenticationContext';
import { Role } from '../../utils/Enums';
interface IAppLayoutProps
{

}

export const AppLayout : React.FunctionComponent<IAppLayoutProps> = (props: React.PropsWithChildren<IAppLayoutProps>) => {
  const navigate: NavigateFunction = useNavigate();
  const { user, logout, setCurrentGuild, isUserInRole } = useAuthenticationContext();
  const [showMobileWarning, setShowMobileWarning] = React.useState<boolean>(false)

  React.useEffect(() => {
    if(window.innerWidth <= 800)
      setShowMobileWarning(true)
  }, []);

  if(user == null) return <></>
  
  let userMenuProps: IContextualMenuProps = {
    items: [
      {
        key: 'changePassword',
        text: 'Skift adgangskode',
        iconProps: { iconName: 'PasswordField' },
        href:"/skift-adgangskode"
      },      
      {
        key: 'signOut',
        text: 'Log af',
        iconProps: { iconName: 'SignOut' },
        onClick: (ev) => {
          logout();
        }
      },
    ]
  };

  if(user.guilds.length > 1)
  {
    userMenuProps.items.splice(0, 0, {
      key: 'switchGuild',
      text: 'Skift vindmøllelaug',
      iconProps: { iconName: 'Switch' },
      onClick: (ev) => {
        setCurrentGuild(null);
        if(window.location.pathname != "/interessenter")
          navigate("/interessenter"); else window.location.reload();
      }
      });
  }

  if(isUserInRole(Role.Administrator))
    {
      userMenuProps.items.splice(0, 0, {
        key: 'userAdministration',
        text: 'Brugeradministration',
        iconProps: { iconName: 'Contact' },
        href:"/brugeradministration"
      });
    }

  return (
       <div className={styles.AppLayout}>               
        <div className={styles.AppHeader}>              
            <div className={styles.AppUserMenu}>            
              <CommandButton className={styles.userMenu} menuProps={userMenuProps}>
              <Persona
                text={user?.name}
                size={11}
              />
              </CommandButton>
            </div>
          </div>
        <div className={styles.AppContent}>
        <div className={styles.AppMainPart} key={"AppLayout"}>
        <div className={styles.contentContainer}>            
            <Outlet />            
            </div>
        </div> 
        </div>
       </div>
      );
  }

  export default AppLayout;
