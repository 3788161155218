import { CommandBar, ICommandBarItemProps } from '@fluentui/react';
import React from 'react';
import appstyles from '../styles/App.module.scss';

interface IListComponentProps
{
    title: string;
    commands: ICommandBarItemProps[];
    farItems: ICommandBarItemProps[];
}

export const ListComponent: React.FunctionComponent<IListComponentProps> = (props: React.PropsWithChildren<IListComponentProps>) => {
    return <><div className={appstyles.heading}><h3>{props.title}</h3></div>
            <CommandBar className={appstyles.commandBar} items={props.commands} styles={{root: {paddingLeft:"0px"}}} farItems={props.farItems} />
            <div style={{margin:"20px"}}>
                {props.children}
            </div>     
            </>
}

export default ListComponent;