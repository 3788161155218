export enum Role
{
    Administrator = "89318bc4-40d8-4daf-8c03-39c8e9b0b4d9"
}

export enum FormMode
{
  New,
  Edit
}

export enum LoginFormType
{
  login,
  requestPassword,
  activateAccount
}