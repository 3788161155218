import React from 'react';
import { useAuthenticationContext } from '../hooks/useAuthenticationContext';
import { IGuild } from '../models/IGuild';
import styles from './../styles/App.module.scss';
import GuildPicker from '../components/GuildPicker';

interface IDashboardProps { }

export const Dashboard: React.FunctionComponent<IDashboardProps> = (props: React.PropsWithChildren<IDashboardProps>) => {    
    const [showGuildPicker, setShowGuildPicker] = React.useState<boolean>(false);
    const { user, guild, setCurrentGuild } = useAuthenticationContext();

    React.useEffect(() => {
        if(user != null && user.guilds.length > 1 && guild == null)
        {
          setShowGuildPicker(true);
        } 
      }, []);
      return <div className={styles.contentContainer}>            
      <div className={styles.heading}><h4>Velkommen, {user?.name} 😎</h4></div>
      <div>Dette system er designet til at hjælpe dig med at administrere dit vindmøllelaug effektivt. Her kan du:
        <ul>
          <li>Vedligeholde interessenter: Registrer og opdater information om laugsmedlemmer, investorer og andre interessenter. Hold styr på deres kontaktoplysninger og roller.</li>
          <li>Udføre udbetalinger: Foretag udbetalinger til laugsmedlemmer baseret på vindmøllernes produktion og økonomiske resultater.</li>
          <li>Ajourføre produktionsoversigten: Få løbende indtastet produktionstal så de er klar til brevfletning</li>
        </ul>
      Vi er her for at gøre din administration mere strømlinet og effektiv. Hvis du har spørgsmål eller brug for hjælp, er du velkommen til at kontakte os. 🌬️💼</div>
      {showGuildPicker && <GuildPicker onSelect={(guild: IGuild | undefined) => { setCurrentGuild(guild as IGuild); setShowGuildPicker(false); } } />}
      </div>
}

export default Dashboard;