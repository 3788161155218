import { IPersonBankRelationship, IStakeholder } from "../models";
import { IGuild } from "../models/IGuild";
import { CreateAuthHeader } from "../utils/Helpers";
import { IService } from "./ICommonServices";

export class PersonBankRelationshipService implements IService<IPersonBankRelationship>
{
    private controller = "api/personbankrelationships";

    public async GetAll(): Promise<IPersonBankRelationship[]> 
    {
        throw new Error("Method not implemented.");
    }

    public async Get(id: number): Promise<IPersonBankRelationship> 
    {
        throw new Error("Method not implemented.");
    }

    public async Create(item: IPersonBankRelationship) : Promise<string>
    {
        const requestOptions = {
            method: 'POST',
            headers: CreateAuthHeader(),
            body: JSON.stringify(item)
        };
    
        const response = await fetch(this.controller, requestOptions);
        return await response.text();
    }

    public async Update(item: IPersonBankRelationship) : Promise<string>
    {
        throw new Error("Method not implemented.");
    }

    public async Delete(id: number) : Promise<string>
    {
        throw new Error("Method not implemented.");
    }   
}
