import { IServiceLetter } from "../models";
import { CreateAuthHeader } from "../utils/Helpers";
import { IService } from "./ICommonServices";

export class ServiceLetterService implements IService<IServiceLetter>
{
    private controller = "api/serviceletters";

    public async GetAll(): Promise<IServiceLetter[]> 
    {
        let result: IServiceLetter[] = [];

        const requestOptions = {
            method: 'GET',
            headers: CreateAuthHeader()
        };
        
        const response = await fetch(this.controller, requestOptions);
        if(!response.ok)
        {
            return result;
        }

        return await response.json() as IServiceLetter[];
    }

    public async Get(id: number): Promise<IServiceLetter> 
    {
        const requestOptions = {
            method: 'GET',
            headers: CreateAuthHeader()
        };
        
        const response = await fetch(`${this.controller}/${id}`, requestOptions);
        if(!response.ok)
        {
            throw new Error("Serviceletter not found");
        }

        return await response.json() as IServiceLetter;
    }

    public async Create(item: IServiceLetter) : Promise<string>
    {
        const requestOptions = {
            method: 'POST',
            headers: CreateAuthHeader(),
            body: JSON.stringify(item)
        };
    
        const response = await fetch(this.controller, requestOptions);
        return await response.text();
    }

    public async Update(item: IServiceLetter) : Promise<string>
    {
        const requestOptions = {
            method: 'PATCH',
            headers: CreateAuthHeader(),
            body: JSON.stringify(item)
        };
    
        const response = await fetch(this.controller, requestOptions);
        return await response.text();
    }

    public async Delete(id: number) : Promise<string>
    {
        const requestOptions = {
            method: 'DELETE',
            headers: CreateAuthHeader()
        };
    
        const response = await fetch(`${this.controller}/${id}`, requestOptions);
        return await response.text();
    }   
}
