import React from 'react';
import { ComboBox, IComboBoxOption } from '@fluentui/react';
import { ApplicationContext } from '../contexts/ApplicationContext';
import { IStakeholder } from '../models/IStakeholder';

interface IStakeholderSelectionProps
{
    label: string;
    disabled: boolean;
    OnStakeholderSelected: (stakeholder: IStakeholder) => void;
}

export const StakeholderSelection: React.FunctionComponent<IStakeholderSelectionProps> = (props: React.PropsWithChildren<IStakeholderSelectionProps>) => {    
    const { Services } = React.useContext(ApplicationContext);

    const [items, setItems] = React.useState<IStakeholder[]>([]);  
    const [selected, setSelected] = React.useState<IStakeholder | undefined>(undefined);
    
      React.useEffect(() => {    
        Fetch();
      }, []);

    const Fetch = async () => {
        Services?.Stakeholder.GetStakeholders().then((stakeholders: IStakeholder[]) => {
            setItems(stakeholders);
        }); 
    }      

    const FetchFull = async (personId: string | number | undefined ) => {
      setSelected(await Services?.Stakeholder.GetStakeholder(parseInt(personId + "")));
    }   
    
    React.useEffect(() => {
      if(selected !== undefined)
      {
        props.OnStakeholderSelected(selected as IStakeholder);
      }
      
    }, [selected?.personID]);
  

    return <ComboBox disabled={props.disabled} onChange={async(ev, option: IComboBoxOption | undefined) => { FetchFull(option?.key) } } key={`stakeholderSelection_${props.label}`} label={props.label} allowFreeInput autoComplete="on" options={items.map((val, i, a) => { return { key: val.personID, text: `${val.personID} - ${val.firstName} ${val.lastName}` } as IComboBoxOption })} />
}

export default StakeholderSelection;