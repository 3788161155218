import React from 'react';
import { ApplicationContext } from '../../../contexts/ApplicationContext';
import { IBank } from '../../../models';
import { ShimmeredDetailsList } from '@fluentui/react/lib/ShimmeredDetailsList';
import { SelectionMode } from '@fluentui/react/lib/Selection';
import { DetailsListLayoutMode, DetailsRow, IColumn, IDetailsListProps, IDetailsRowStyles } from '@fluentui/react/lib/DetailsList';
import { DefaultButton, ICommandBarItemProps, SearchBox } from '@fluentui/react';
import styles from '../../../styles/App.module.scss';
import ListComponent from '../../../components/ListComponent';
import useObject from '../../../hooks/useObject';
import { FormMode } from '../../../utils/Enums';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import BanksPanel from './BanksPanel';

interface IBanksProps
{

}

export const Banks: React.FunctionComponent<IBanksProps> = (props: React.PropsWithChildren<IBanksProps>) => {
  const navigate: NavigateFunction = useNavigate();    
  const { Services } = React.useContext(ApplicationContext);
    const [items, setItems] = React.useState<IBank[]>([]);  
    const [selected, setSelected] = React.useState<IBank | undefined>(undefined);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [showPanel, setShowPanel] = React.useState<boolean>(false);
    const { value: entity, updateValue: updateEntity, setValue } = useObject<IBank>({ regNo: 0, name: ""} as IBank);
    const [formMode, setFormMode] = React.useState<FormMode>(FormMode.New);

    React.useEffect(() => {    
        Fetch();
      }, []);

      React.useEffect(() => {
        setValue(selected as IBank);
      }, [selected]);

    const Fetch = async () => {
    Services?.Bank.GetAll().then((result: IBank[]) => {          
        setIsLoading(true);
        setItems(result);
        setIsLoading(false);
      }); 
}

      const COMMANDBAR_COLUMNS = [
        {
          key: 'regNo',
          name: 'Reg. nr.',
          fieldName: 'regNo',
          minWidth: 100,
          maxWidth: 100,
          isResizable: true
        },
        {
          key: 'name',
          name: 'Navn',
          fieldName: 'name',
          minWidth: 150,
          maxWidth: 250,
          isResizable: true
        }
      ] as IColumn[];

      const Commands: ICommandBarItemProps[] = [
        {
            key: 'create',
            text: 'Opret',
            iconProps: { iconName: 'Add' },
            onClick: () => { 
                Reset(); setFormMode(FormMode.New); setShowPanel(true);
             },
            //disabled: selected != undefined
        }
        ];

      const CreateFarMenuItems = [
        {        
          key:"search",
          onRender:() => <SearchBox 
            placeholder="Søg efter ..." 
            className={styles.searchBox} 
            onSearch={(newValue) => { /*this.onSearch(newValue);*/ } } 
            onChange={(event?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => { /*onSearch(newValue);*/ }}
            onClear={ev => { /*onSearch("");*/ }}
            />
        }
      ];

      const _onRenderRow: IDetailsListProps['onRenderRow'] = props => {
        const customStyles: Partial<IDetailsRowStyles> = {};
        if (props) {
          return <div title="Klik for at åbne" style={{cursor: "pointer"}} onClick={() => { setFormMode(FormMode.Edit); setSelected(props.item); setShowPanel(true); }}><DetailsRow {...props} styles={customStyles} /></div>
        }
        return null;
      }

      const AddOrUpdate = async () =>
    {
        let result : string | undefined = undefined;
        
        switch(formMode)
        {
            case FormMode.New:
                result = await Services?.Bank.Create(entity);
                break;
            case FormMode.Edit:
                result = await Services?.Bank.Update(entity);
                break;
        }
        
        if(result === "OK")
        {
            Reset();
            Fetch();
        } else
        {
            alert(result);
        }
    }

    const Reset = () => {
        setSelected(undefined);
        setShowPanel(false);
    }
    
    return <div>
                <ListComponent title="Banker" commands={Commands} farItems={CreateFarMenuItems}>
                    <ShimmeredDetailsList
                    onShouldVirtualize={() => false }
                    items={items}
                    columns={COMMANDBAR_COLUMNS}
                    selectionMode={SelectionMode.none}     
                    onRenderRow={_onRenderRow?.bind(this)}           
                    layoutMode={DetailsListLayoutMode.justified}
                    isHeaderVisible={true}
                    enableShimmer={isLoading}
                    setKey="set"
                    />
                </ListComponent>
                <DefaultButton text="Tilbage" onClick={() => { navigate("/indstillinger"); }} />
                { showPanel && 
                    <BanksPanel selected={selected as IBank} formMode={formMode} onDismiss={(update: boolean) => {if(update) Fetch(); setShowPanel(false); }} />
                }                    
           </div>
}

export default Banks;