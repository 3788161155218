import { CompoundButton, Dropdown, IDropdownOption } from '@fluentui/react';
import React from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { ApplicationContext } from '../contexts/ApplicationContext';
import EditableNumberTextField from '../components/EditableNumberTextField';
import styles from './../styles/Common.module.scss';
import { IProductionOverview, IProductionOverviewUpdate } from '../models';
import { ProductionOverviewService } from '../services';

interface IProductionOverviewProps
{

}

interface ITurbineProductionOverviewItem
{
    name: string;
    production: IProductionOverview;
    regulation: IProductionOverview;
    total: IProductionOverview;
}

const Months = ["Januar", "Februar", "Marts", "April", "Maj", "Juni", "Juli", "August", "September", "Oktober", "November", "December"];
export const ProductionOverview: React.FunctionComponent<IProductionOverviewProps> = (props: React.PropsWithChildren<IProductionOverviewProps>) => {
    const navigate: NavigateFunction = useNavigate();  
    const { Services } = React.useContext(ApplicationContext);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [productionItems, setProductionItems] = React.useState<IProductionOverview[]>([]);
    const [year, setYear] = React.useState<number>(new Date().getFullYear()); 

    React.useEffect(() => {    
        Fetch();
      }, []);

      const Fetch = async () => {
        setIsLoading(true);
          Services?.ProductionOverview.GetAll().then((result: IProductionOverview[]) => {                              
            setProductionItems(result);
              setIsLoading(false);
            });     
      }

      const years = productionItems.map(item => item.year).filter((value, index, self) => self.indexOf(value) === index).sort().reverse();
      const records = productionItems.filter(item => item.year === year);
      const getValueByMonth = (record: IProductionOverview, month: number) : number => {
        if(record === undefined || record === null) return 0;
        switch(month)
        {
            case 0: return record.january;
            case 1: return record.february;
            case 2: return record.march;
            case 3: return record.april;
            case 4: return record.may;
            case 5: return record.june;
            case 6: return record.july;
            case 7: return record.august;
            case 8: return record.september;
            case 9: return record.october;
            case 10: return record.november;
            case 11: return record.december;            
        }        
        return 0;
      };

      const getValuePctByMonth = (record: IProductionOverview, month: number) : number => {
        if(record === undefined || record === null) return 0;
        switch(month)
        {
            case 0: return record.januaryPct;
            case 1: return record.februaryPct;
            case 2: return record.marchPct;
            case 3: return record.aprilPct;
            case 4: return record.mayPct;
            case 5: return record.junePct;
            case 6: return record.julyPct;
            case 7: return record.augustPct;
            case 8: return record.septemberPct;
            case 9: return record.octoberPct;
            case 10: return record.novemberPct;
            case 11: return record.decemberPct;            
        }        
        return 0;
      };

      const UpdateMonthValue = (record: IProductionOverview, month: number, newValue: number) => {        
        switch(month)
        {
            case 0: record.january = newValue; break;
            case 1: record.february = newValue; break;
            case 2: record.march = newValue; break;
            case 3: record.april = newValue; break;
            case 4: record.may = newValue; break;
            case 5: record.june = newValue; break;
            case 6: record.july = newValue; break;
            case 7: record.august = newValue; break;
            case 8: record.september = newValue; break;
            case 9: record.october = newValue; break;
            case 10: record.november = newValue; break;
            case 11: record.december = newValue; break;            
        }

        return record;
      };

      const SaveChanges = async (turbine: ITurbineProductionOverviewItem) => {        
        const updateObject = {
            turbineID: turbine.production.turbineID,
            year: turbine.production.year,
            production: turbine.production,
            regulation: turbine.regulation
         } as IProductionOverviewUpdate;

         if(updateObject.production.guarantee === 0 && turbine.total.guarantee > 0)
         {
                updateObject.production.guarantee = turbine.total.guarantee;
         }

        const updateResult = await (Services?.ProductionOverview as ProductionOverviewService).UpdateFull(updateObject);
        
        if(updateResult !== "OK")
        {
            alert(updateResult);
        } else
        {
            Fetch();
        }
      };

      const SaveChangesAverage = async (record: IProductionOverview) => {        
        const updateObject = {
            turbineID: record.turbineID,
            year: record.year,
            production: record,
            regulation: null
         } as IProductionOverviewUpdate;

        const updateResult = await (Services?.ProductionOverview as ProductionOverviewService).UpdateFull(updateObject);
        
        if(updateResult !== "OK")
        {
            alert(updateResult);
        } else
        {
            Fetch();
        }
      };

      if(records.length === 0)
        return <></>

    const groupedRecords = records.reduce((acc, record) => {
        const name = record.name;
        if (!acc[name]) {
            acc[name] = [];
        }
        acc[name].push(record);
        return acc;
    }, {} as { [key: string]: IProductionOverview[] });

    const re = [] as ITurbineProductionOverviewItem[];
    
    Object.keys(groupedRecords).forEach(name => {
        if(name === "Alle møller" || name === "#GEN#") return;
        re.push({ 
            name: name,
            production: groupedRecords[name].find(x => x.info == "1_Prod") as IProductionOverview,
            regulation: groupedRecords[name].find(x => x.info == "2_Regul") as IProductionOverview,
            total: groupedRecords[name].find(x => x.info == "3_Ialt") as IProductionOverview
         } as ITurbineProductionOverviewItem);        
    });

    const total = (groupedRecords["Alle møller"] as IProductionOverview[]).at(0) as IProductionOverview;
    const average = (groupedRecords["#GEN#"] as IProductionOverview[]).at(0) as IProductionOverview;

    const calculateYTDPct = (item: ITurbineProductionOverviewItem) : string => {
        const monthsFilled = [
            item.total.january, item.total.february, item.total.march, item.total.april,
            item.total.may, item.total.june, item.total.july, item.total.august,
            item.total.september, item.total.october, item.total.november, item.total.december
        ].filter(value => value > 0).length;

        return (((item.total.prodTotal / item.total.guarantee) * 100) / monthsFilled).toFixed(2);
    }

    return <div>
                <h3>Produktionsoversigt</h3>
                <Dropdown label='Vælg år' style={{width:"100px"}} selectedKey={year} onChange={(ev, option) => { setYear(option?.key as number) }} options={years.map((val, i, a) => { return { key: val, text: val + "" } as IDropdownOption; })}/>
                <div className={styles.table}>
                  <div className={styles.tableRow}>
                    {/* Header */}
                    <div className={styles.tableCell} style={{minWidth:"100px"}}><b>Måned</b></div>
                      {re.map((item, i, a) => {
                          return <>
                            <div className={styles.tableCell} style={{textAlign:"right"}}><b>{item?.name}</b></div>
                            <div className={styles.tableCell} style={{textAlign:"right"}}><b>Regulering</b></div>
                            <div className={styles.tableCell} style={{textAlign:"right"}}><b>I alt</b></div>
                            </>
                      })}
                      <div className={styles.tableCell} style={{textAlign:"right"}}><b>Mdr. kWh</b></div>
                      <div className={styles.tableCell} style={{textAlign:"right"}}><b>Gen.</b></div>
                      <div className={styles.tableCell} style={{textAlign:"right"}}><b>Gen. DK</b></div>
                    </div>
                    {/* Body */}
                    {Months.map((month, i, a) => {
                      return <div className={styles.tableRow}>
                                <div className={styles.tableCell}>{month}</div>
                                  {re.map((item, rIndex, a) => {
                                    return <>
                                    <div className={styles.tableCell}>
                                        <EditableNumberTextField value={getValueByMonth(item.production, i).toString()} onValueChanged={(newValue: number) => { const record = UpdateMonthValue(item.production, i, newValue); SaveChanges(item); }} />
                                    </div>
                                    <div className={styles.tableCell}>
                                        <EditableNumberTextField value={getValueByMonth(item.regulation, i).toString()} onValueChanged={(newValue: number) => { const record = UpdateMonthValue(item.regulation, i, newValue); SaveChanges(item); }} />
                                    </div>
                                    <div className={styles.tableCell}>
                                        <EditableNumberTextField value={getValueByMonth(item.total, i).toString()} disabled />
                                    </div>
                                    </>                                    
                                    })}
                                    <div className={styles.tableCell}>
                                        <EditableNumberTextField value={getValueByMonth(total, i).toString()} disabled />
                                    </div>
                                    <div className={styles.tableCell}>
                                        <EditableNumberTextField value={getValuePctByMonth(total, i).toString()} disabled suffix={"%"} showDecimal={true} />
                                    </div>
                                    <div className={styles.tableCell}>
                                        <EditableNumberTextField value={getValueByMonth(average, i).toString()} onValueChanged={(newValue: number) => { const record = UpdateMonthValue(average, i, newValue); SaveChangesAverage(record);  }}  />
                                    </div>
                              </div>
                      }                      
                    )}
                    <div className={styles.tableRow}>
                        <div className={styles.tableCell}><b>Totaler</b></div>
                            {re.map((item, i, a) => {
                                return <>
                                    <div className={styles.tableCell}>
                                        <EditableNumberTextField value={(item.production.january+item.production.february+item.production.march+item.production.april+item.production.may+item.production.june+item.production.july+item.production.august+item.production.september+item.production.october+item.production.november+item.production.december)+""} disabled />
                                    </div>
                                    <div className={styles.tableCell}>
                                        <EditableNumberTextField value={(item.regulation.january+item.regulation.february+item.regulation.march+item.regulation.april+item.regulation.may+item.regulation.june+item.regulation.july+item.regulation.august+item.regulation.september+item.regulation.october+item.regulation.november+item.regulation.december)+""} disabled />
                                    </div>
                                    <div className={styles.tableCell}>
                                        <EditableNumberTextField value={(item.total.january+item.total.february+item.total.march+item.total.april+item.total.may+item.total.june+item.total.july+item.total.august+item.total.september+item.total.october+item.total.november+item.total.december)+""} disabled />
                                    </div>
                                </>
                            })}                        
                        <div className={styles.tableCell}>
                            <EditableNumberTextField value={(total.january+total.february+total.march+total.april+total.may+total.june+total.july+total.august+total.september+total.october+total.november+total.december)+""} disabled />
                        </div>
                        <div className={styles.tableCell}>
                            <EditableNumberTextField value={total.totalPct + ""} disabled suffix={"%"} />
                        </div>
                        <div className={styles.tableCell}>
                            <EditableNumberTextField value={(average.january+average.february+average.march+average.april+average.may+average.june+average.july+average.august+average.september+average.october+average.november+average.december)+""} disabled />
                        </div>
                    </div> 
                    <div className={styles.tableRow}>
                        <div className={styles.tableCell}><b>Mdr. garanti</b></div>
                        {re.map((item, i, a) => {
                            return <>
                                <div className={styles.tableCell}>
                                    <EditableNumberTextField value={item.total.guarantee + ""} onValueChanged={(newValue: number) => { item.production.guarantee = newValue; SaveChanges(item); } } />
                                </div>
                                <div className={styles.tableCell}>
                                    <EditableNumberTextField value={""} disabled />
                                </div>
                                <div className={styles.tableCell}>
                                    <EditableNumberTextField value={""} disabled />
                                </div>
                            </>
                        })}
                    </div>
                    <div className={styles.tableRow}>
                        <div className={styles.tableCell}><b>År til dato i %</b></div>
                        {re.map((item, i, a) => {
                            return <>
                            <div className={styles.tableCell}>
                                <EditableNumberTextField value={""} disabled />
                            </div>
                            <div className={styles.tableCell}>
                                <EditableNumberTextField value={""} disabled />
                            </div>
                            <div className={styles.tableCell}>                                
                                <EditableNumberTextField value={calculateYTDPct(item)} disabled showDecimal={true} />
                            </div>
                            </>
                        })}
                        </div>
                </div>                    
           </div>
}

export default ProductionOverview;