import * as React from 'react';
import { PrimaryButton } from '@fluentui/react';

export interface IButtonProps {
    text: string;
    disabled: boolean;
}

export const CustomPrimaryButton: React.FunctionComponent<IButtonProps> = (props: React.PropsWithChildren<IButtonProps>) => {
  return (
    <PrimaryButton styles={{root: { borderRadius:"20px" }}} type="submit" disabled={props.disabled} >{props.text}</PrimaryButton>              
  );
};

export default CustomPrimaryButton;