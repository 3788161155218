import { CompoundButton, Stack } from '@fluentui/react';
import React from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useAuthenticationContext } from '../../hooks/useAuthenticationContext';
import { Role } from '../../utils/Enums';

interface ISettingsProps
{

}

export const Settings: React.FunctionComponent<ISettingsProps> = (props: React.PropsWithChildren<ISettingsProps>) => {
    const navigate: NavigateFunction = useNavigate();  
    const { isUserInRole } = useAuthenticationContext();

    return <div>
                <h3>Indstillinger</h3>                
                <div style={{display:"grid", gap:"5px",gridTemplateColumns:"repeat(auto-fill, minmax(250px, 1fr))"}}>
                    <CompoundButton text='Banker' secondaryText='Her kan du oprette og ændre alle banker' onClick={() => { navigate("banker"); }}/>                    
                    {isUserInRole(Role.Administrator) && <CompoundButton text='Vindmøllelauget (kommer senere)' secondaryText='Her kan opdatere stamdata for vindmøllelauget' disabled/>}
                </div>
           </div>
}

export default Settings;