import { IStakeholder } from "../models";
import { IGuild } from "../models/IGuild";
import { CreateAuthHeader } from "../utils/Helpers";


export interface IStakeholderService
{
    GetStakeholders() : Promise<IStakeholder[]>;
    GetStakeholder(id: number) : Promise<IStakeholder>;
    CreateStakeholder(stakeholder: IStakeholder) : Promise<string>;
    UpdateStakeholder(stakeholder: IStakeholder) : Promise<boolean | string>;
}

export class StakeholderServiceProvider implements IStakeholderService
{
    private controller = "api/stakeholders";

    public async GetStakeholders(): Promise<IStakeholder[]> 
    {
        let stakeholders: IStakeholder[] = [];

        const requestOptions = {
            method: 'GET',
            headers: CreateAuthHeader()
        };
        
        const response = await fetch(this.controller, requestOptions);
        if(!response.ok)
        {
            return stakeholders;
        }

        return await response.json() as IStakeholder[];
    }

    public async GetStakeholder(id: number): Promise<IStakeholder> 
    {
        const requestOptions = {
            method: 'GET',
            headers: CreateAuthHeader()
        };
        
        const response = await fetch(`${this.controller}/${id}`, requestOptions);
        if(!response.ok)
        {
            throw new Error("Stakeholder not found");
        }

        let result = await response.json() as IStakeholder;

        if(result.shares?.length > 0)
        {
            result.shares.forEach(share => {
                share.buyDate = new Date(share.buyDate);
            });
        }

        return result;
    }

    public async CreateStakeholder(stakeholder: IStakeholder) : Promise<string>
    {
        const requestOptions = {
            method: 'POST',
            headers: CreateAuthHeader(),
            body: JSON.stringify(stakeholder)
        };
    
        const response = await fetch(this.controller, requestOptions);
        if(!response.ok)
        {
            return "";
        }        

        let res = await response.text();

        return res;
    }

    public async UpdateStakeholder(stakeholder: IStakeholder) : Promise<boolean | string>
    {
        const requestOptions = {
            method: 'PATCH',
            headers: CreateAuthHeader(),
            body: JSON.stringify(stakeholder)
        };
    
        const response = await fetch(this.controller, requestOptions);
        if(response.ok)
        {
            return true;
        } else
        {
            let res = await response.text();

            return res;
        } 
    }
}
