import React from 'react';
import { ApplicationContext } from '../../../contexts/ApplicationContext';
import { DefaultButton, Panel, PanelType, PrimaryButton, TextField } from '@fluentui/react';
import useObject from '../../../hooks/useObject';
import { IBank } from '../../../models';
import { FormMode } from '../../../utils/Enums';

interface IBanksPanelProps
{
    onDismiss(update: boolean, hint: number): void;
    formMode: FormMode;
    selected: IBank | undefined;
}

export const BanksPanel : React.FunctionComponent<IBanksPanelProps> = (props: React.PropsWithChildren<IBanksPanelProps>) => {
    const { Services } = React.useContext(ApplicationContext);    
    const { value: entity, updateValue: updateEntity, setValue } = useObject<IBank>(props.selected ?? { regNo: undefined, name: "" } as IBank);
    
    const AddOrUpdate = async () =>
        {
            let result : string | undefined = undefined;
            
            switch(props.formMode)
            {
                case FormMode.New:
                    result = await Services?.Bank.Create(entity);
                    break;
                case FormMode.Edit:
                    result = await Services?.Bank.Update(entity);
                    break;
            }
            
            if(result === "OK")
            {
                Reset(true, entity.regNo);                
            } else
            {
                alert(result);
            }
        }
    
        const Delete = async() => {
            if(window.confirm("Er du sikker?") && entity.regNo)
            {
                let result = await Services?.Bank.Delete(entity.regNo);
    
                if(result === "OK")
                {
                    Reset(true);
                } else
                {
                    alert(result);
                }
            }
        }
    
        const Reset = (update: boolean, hint: number = -1 ) => {
            props.onDismiss(update, hint);
        }

    return (
        <Panel
                    headerText={props.formMode === FormMode.New ? "Opret bank" : "Rediger bank"}
                    isOpen={true}                           
                    isLightDismiss={false}
                    isBlocking={true}
                    onDismiss={(ev?: React.SyntheticEvent<HTMLElement> | KeyboardEvent) => { if((ev as any).keyCode !== 27) Reset(false); }}              
                    closeButtonAriaLabel="Luk panel"
                    onRenderFooterContent={() => {
                    return <>
                        <PrimaryButton styles={{root: {marginRight:"10px"}}} onClick={() => AddOrUpdate()} text={props.formMode === FormMode.New ? "Opret" : "Gem ændringer"} />
                        <DefaultButton styles={{root: {marginRight:"10px"}}} onClick={() => Reset(false) } text="Annuller" />                  
                        {props.formMode === FormMode.Edit && <PrimaryButton onClick={() => Delete()} text="Slet" styles={{root: {background:"firebrick", border:"1px solid red"}, rootHovered: { background:"firebrick", border:"1px solid red" }}} />}
                    </>
                    }}
                    type={PanelType.medium}>                            
                            <div>                            
                                <TextField type='number' label="Reg. nr." value={entity.regNo !== undefined ? entity.regNo + "" : ""} placeholder='Angiv bankens registreringsnummer - eks. 7621' disabled={props.formMode === FormMode.Edit} onChange={(ev, newValue: string | undefined) => { updateEntity({regNo: newValue !== undefined && newValue !== "" ? parseInt(newValue) : undefined}) }} />                    
                                <TextField label={"Navn"} value={entity.name !== undefined ? entity.name + "" : ""} placeholder='Angiv bankens navn, eks. JYSKE BANK A/S, BRANDE' onChange={(ev, newValue: string | undefined) => { if(newValue !== undefined) updateEntity({name: newValue}) }} />
                            </div>
                    </Panel>
    );
}

export default BanksPanel;